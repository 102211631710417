<template>
  <router-view></router-view>
</template>

<script>
import StepFooter from "../../components/register-step/step-footer";
export default {
  components: {
    StepFooter,
  },
  data() {
    return {
      form: {},
      industrys: [],
    };
  },
  methods: {},
  created() {},
};
</script>
